import React, { useContext, Component } from 'react';
import { format } from 'date-fns';
import { AuthContext } from './AuthContext';

import api from '../../utils/api';

export const BusContext = React.createContext();

export class BusContextProvider extends Component {
  static contextType = AuthContext;

  state = {
    equipmentStatus: null,
    engineDetails: null,
    equipmentIssues: null,
    totalIssueCount: 0,
    customerId: null,
    customerNameData: null,
    averageKM: null
  };

  componentDidMount() {
    this.getAllData();
    this.getAllDataWithInterval = setInterval(this.getAllData, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.getAllDataWithInterval);
  }

  // get all required data for bus context and set in state
  getAllData = () => {
    const { userID, customerList } = this.context;
    api.getCustomerName
      .get({
        custSK: customerList
      })
      .then(customerName => {
        this.setState({ customerNameData: customerName });
        const engineDetails = [];
        let totalIssueCount = 0;

        if (customerName && customerName.engine) {
          Object.keys(customerName.engine).forEach(key => {
            const obj = customerName.engine[key];
            totalIssueCount += obj.count;
            engineDetails.push({
              equipmentId: obj.equipId,
              engineSerialNumber: key,
              serviceModelName: obj.smn,
              vin: obj.vin
            });
          });
        }

        const dateStr = format(new Date(), 'yyyy-MM-dd');

        Promise.all([
          api.totalKwhPerMile.get({
            startDate: dateStr,
            endDate: dateStr,
            fleetLevel: 0,
            custName: customerName.custName
          }),
          api.equipmentStatus.get(customerName.custName),
          api.activeAlerts.get({
            userId: userID,
            customerId: customerList
          }),
          api.busIssues.get({ 
            customerId: customerList 
          })
        ]).then(
          ([averageKM, equipmentStatus, activeAlertData, equipmentIssues]) => {

            // Sort equipmentStatus based on customerEquipId
            equipmentStatus.sort((a, b) => {
              const equipIdA = a.customerEquipId.S;
              const equipIdB = b.customerEquipId.S;

              // Try to convert both IDs to numbers
              const numA = Number(equipIdA);
              const numB = Number(equipIdB);

              // If both are numbers, sort numerically
              if (!Number.isNaN(numA) && !Number.isNaN(numB)) {
                return numA - numB;
              }

              // If either or both are strings, sort lexicographically
              const equipIdAStr = equipIdA.toString().toUpperCase();
              const equipIdBStr = equipIdB.toString().toUpperCase();

              if (equipIdAStr < equipIdBStr) return -1;
              if (equipIdAStr > equipIdBStr) return 1;
              return 0;
            });

            const averageKmMap = {};
            
            for(let i=0; i < averageKM.length; i += 1){
              const key = averageKM[i];
              averageKmMap[key.engineSerialNumber] = key;
            }

            activeAlertData.forEach(fault => {
              fault.isDisplay = fault.severityId >= 1 && fault.severityId <= 4;
            });

            const { faults } = JSON.parse(JSON.stringify(equipmentIssues));

            const statusCopy = JSON.parse(JSON.stringify(equipmentStatus));

            const busStatus = {};

            statusCopy.forEach(bus => {
              const tempObject = {};
              tempObject.faults = [];
              tempObject.alerts = [];
              Object.entries(bus).forEach(([key, value]) => {
                [tempObject[key]] = Object.values(value);
              });
              busStatus[bus.esn.S] = tempObject;
            });

            if (activeAlertData[0].statusMessage !== 'NO ALERTS') {
              activeAlertData.forEach(alert => {
                const busStatusEntry = busStatus[alert.engineSerialNumber];
                if (busStatusEntry && busStatusEntry.alerts) {
                  busStatusEntry.alerts.push(alert);
                }
              });
              activeAlertData.sort((a, b) => a.severityId - b.severityId);
            } else {
              activeAlertData = [];
            }

            if (faults && faults.length > 0) {
              faults.forEach(fault => {
                if (busStatus[fault.esn])
                  busStatus[fault.esn].faults.push(fault);
              });
              faults.forEach(fault => {
                fault.busStatus = busStatus[fault.esn];
                switch (fault.priorityCode) {
                  case 1:
                    fault.severity = 'Immediate';
                    fault.color = '#e53935';
                    break;
                  case 2:
                    fault.severity = 'Service Within 3 Days';
                    fault.color = '#ff7043';
                    break;
                  case 3:
                    fault.severity = 'Service Within 7 Days';
                    fault.color = '#ffb300';
                    break;
                  case 4:
                    fault.severity = 'Next Planned Maintenance';
                    fault.color = '#00bcd4';
                    break;
                  default:
                    fault.severity = 'Not Available';
                    fault.color = '#000000';
                }
              });
            }

            this.setState({
              activeAlertData,
              averageKM: averageKmMap,
              customerId: customerList,
              engineDetails,
              equipmentIssues,
              equipmentStatus,
              totalIssueCount,
              busStatus,
              faults
            });
          }
        );
      });
  };

  render() {
    return (
      <BusContext.Provider
        value={{
          ...this.state,
          getAllData: this.getAllData
        }}
      >
        {this.props.children}
      </BusContext.Provider>
    );
  }
}

export default BusContext;

export const useBus = () => useContext(BusContext);
