import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from 'components/Contexts/AuthContext';
import { CircularProgress, Paper } from '@material-ui/core';
import api from 'utils/api';
import { SimulatorAddRoute } from './SimulatorAddRoute/SimulatorAddRoute.jsx';
import { SimulatorForm } from './SimulatorForm/SimulatorForm.jsx';
import { SimulatorResults } from './SimulatorResults/SimulatorResults';
import { SimulatorStatus } from './SimulatorStatus/SimulatorStatus.jsx';
import {
  mapResults,
  mapRouteInfo,
  mapSimulation,
  mapTireInfo,
  mapVehicleInfo
} from './simulatorUtils';
import './Simulator.module.css';

const pageState = {
  COMPLETE: 'complete',
  NO_ROUTES: 'noRoutes',
  SUBMITTING: 'submitting',
  WAITING: 'waiting'
};

const Simulator = () => {
  const { simulationId, tab } = useParams();
  const history = useHistory();

  const [status, setStatus] = useState(pageState.WAITING);
  const [data, setData] = useState({
    job: null,
    mappedTires: {},
    mappedVehicles: {},
    results: null,
    routes: null,
    simulation: null,
    tires: null,
    vehicles: null
  });
  const { userID, customerList } = useAuth();
  const mountRef = useRef((new Date()).getTime());

  // Handle loading data for the page
  useEffect(() => {
    const localRef = (new Date()).getTime();
    mountRef.current = localRef;
    if (tab !== 'saved') {
      setStatus(pageState.WAITING);

      // Data required for dropdowns
      const metadataPromises = [
        api.simulation.getRoutes(),
        api.simulation.getTires(),
        api.simulation.getVehicles()
      ];

      let pagePromises;
      if (simulationId) {
        // Data to show simulation params and results
        pagePromises = metadataPromises.concat([
          api.simulation.getSimulation(simulationId),
          api.simulation.getResults(simulationId)
        ]);
      } else {
        pagePromises = metadataPromises;
      }

      Promise.all(pagePromises)
      .then(([routeRes, tireRes, vehicleRes, simRes, resultRes]) => {
        const routeData = mapRouteInfo(routeRes);
        const tireData = mapTireInfo(tireRes);
        const vehicleData = mapVehicleInfo(vehicleRes);
        const simulation = mapSimulation(simulationId, {
          defaultRoute: routeData.defaultRoute,
          defaultTire: tireData.defaultTire,
          defaultVehicle: vehicleData.defaultBus,
          simRes
        });
        const results = mapResults(resultRes);

        if (localRef === mountRef.current) {
          setData({
            job: simRes || null,
            mappedTires: tireData.mappedTires,
            mappedVehicles: vehicleData.mappedVehicles,
            results,
            routes: routeData.routes,
            simulation,
            tires: tireData.tires,
            vehicles: vehicleData.vehicles
          });

          setStatus(routeData.hasRoutes ? pageState.COMPLETE : pageState.NO_ROUTES);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    return () => {
      // This kicks off when the component is unmounted.
      mountRef.current = 0;
    };
  }, [simulationId, tab]);

  useEffect(() => {
    // metaCx event
    api.metaCx.addCommment({
      userId: userID,
      eventKey: 'rangeestimationpage_view',
      source: 'external',
      eventDate: Date.now(),
      companyName: '',
      companyId: customerList,
      busId: null,
      busName: ""
      }).then((response)=>{
      if (response?.status !== 204)
      {
        console.log('metaCx did not return 204.');
      }
      });
  },[userID]);

  const handleSubmission = (form) => {
    const submitRef = mountRef.current;
    setStatus(pageState.SUBMITTING);
    setData({
      ...data,
      job: null,
      results: null
    });

    // map to the payload
    const payload = {
      ambientTemp: form.temperature,
      batteryStateOfCharge: form.battery,
      driverBehavior: Number(form.driverExpertise),
      ridership: Number(form.ridership),
      routeName: form.route,
      scenarioName: form.scenarioName,
      tire: data.mappedTires[form.tire],
      vehicle: data.mappedVehicles[form.bus]
    };

    // call the endpoint...
    api.simulation.postSimulation(payload)
    .then((response) => {
      if (submitRef === mountRef.current) {
        setData({
          ...data,
          job: response,
          results: null
        });
        history.push(`/range-estimation/scenario/${response.jobId}`);
        setStatus(pageState.COMPLETE);
      }
    })
    .catch((error) => {
      console.log(error);
    });
        try {
          // metaCx event
          api.metaCx.addCommment({
            userId: userID,
            eventKey: 'rangeestimation_ran',
            source: 'external',
            eventDate: Date.now(),
            companyName: '',
            companyId: customerList,
            busId: data.mappedVehicles[form.bus].vehicleId,
            busName: data.mappedVehicles[form.bus].designation
          }).then((response)=>{
            if (response?.status !== 204)
            {
              console.log('metaCx did not return 204.');
            }
          });
      }catch (err) {
          console.log('error.',err);
      }
  };

  const requestResults = () => {
    if (simulationId) {
      // if simulationId isn't present, this should not have been called!
      const getRef = mountRef.current;
      api.simulation.getResults(simulationId)
      .then((resultRes) => {
        if (getRef === mountRef.current) {
          const results = mapResults(resultRes);
          setData({
            ...data,
            results
          });
        }


      })
      .catch((error) => {
        console.log(error);
      });

    }
  };

  return (
    <div className="simulator">
      { status === pageState.WAITING &&
        <div className='page-loader'>
          <CircularProgress/>
        </div>
      }
      { status !== pageState.WAITING &&
        <>
          <Paper className="inputs">
            { status === pageState.NO_ROUTES && <SimulatorAddRoute/> }
            { (status === pageState.COMPLETE || status === pageState.SUBMITTING) &&
              <SimulatorForm
                buses={data.vehicles}
                onSubmit={handleSubmission}
                routes={data.routes}
                simulation={data.simulation}
                tires={data.tires}
              />
            }
            { status === pageState.SUBMITTING &&
              <div className='overlay-loader'>
                <CircularProgress/>
              </div>
            }
          </Paper>
          <Paper className="results">
            { status === pageState.COMPLETE && data.results && data && <SimulatorResults results={data.results} data={data}/> }
            { status === pageState.COMPLETE && !data.results && <SimulatorStatus onRequestResults={requestResults} simulation={data.job} /> }
          </Paper>
        </>
      }
    </div>
  );
};

export default Simulator;
