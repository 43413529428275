import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  TextField,
  Tooltip
} from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { logEvent } from 'utils/analyticsTracking';
import './SimulationForm.module.css';


const SimulatorFormComponent = ({ buses, onSubmit, routes, simulation, tires }) => {
  const [form, setForm] = useState(simulation);
  const [errors, setErrors] = useState({
    bus: false,
    route: false,
    scenarioName: false,
    tire: false
  });

  // When the simulation prop changes, make sure to set the form.
  useEffect(() => {
    setForm({
      ...simulation
    });
  }, [simulation]);

  // Slider components call this directly, because they are not standard inputs
  // and do not emit a standard data change event.
  const setVal = (name, val) => {
    setForm({
      ...form,
      [name]: val
    });
  };

  // Handle a standard input data change event
  const handleChange = (e) => {
      setVal(e.target.name, e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const fieldErrors = {
    };
    if (!form.bus) {
      fieldErrors.bus = true;
    }
    if (!form.route) {
      fieldErrors.route = true;
    }
    if (!form.scenarioName) {
      fieldErrors.scenarioName = true;
    }
    if (!form.tire) {
      fieldErrors.tire = true;
    }
    setErrors(fieldErrors);
    if (!fieldErrors.bus && !fieldErrors.route  && !fieldErrors.scenarioName && !fieldErrors.tire) {
      logEvent({
        action: 'click',
        category: 'Range Estimation',
        label: 'submit'
      });
      onSubmit(form);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="inputRow">
        <div>
          <div className="label">
            <InputLabel htmlFor="scenarioName">Scenario Name</InputLabel>
            <Tooltip title="The name to save the scenario under in 'Saved Scenarios'">
              <Help color="action"/>
            </Tooltip>
          </div>
          <TextField
            className="formControl"
            error={errors.scenarioName}
            helperText={errors.scenarioName && 'Required'}
            id="scenarioName"
            name="scenarioName"
            onChange={handleChange}
            value={form.scenarioName}
            variant="outlined"
          />
        </div>
        <div>
          <div className="label">
            <InputLabel id="busLabel">Bus</InputLabel>
            <Tooltip title="Select the vehicle for this route">
              <Help color="action"/>
            </Tooltip>
          </div>
          <Select
            className="formControl"
            error={errors.bus}
            labelId="busLabel"
            name="bus"
            onChange={handleChange}
            value={form.bus}
            variant="outlined"
          >
            { buses.map((bus) => <MenuItem value={bus.id} key={`bus-${bus.id}`}>{bus.name}</MenuItem> )}
          </Select>
          { errors.bus && <FormHelperText className="MuiFormHelperText-contained Mui-error">Required</FormHelperText> }
        </div>
        <div>
          <div className="label">
            <InputLabel id="routeLabel">Route</InputLabel>
            <Tooltip title="The route to simulate">
              <Help color="action"/>
            </Tooltip>
          </div>
          <Select
            className="formControl"
            error={errors.route}
            labelId="routeLabel"
            name="route"
            onChange={handleChange}
            value={form.route}
            variant="outlined"
          >
            { routes.map((route) => <MenuItem value={route.id} key={`route-${route.id}`}>{route.name}</MenuItem> )}
          </Select>
          { errors.route && <FormHelperText className="MuiFormHelperText-contained Mui-error">Required</FormHelperText> }
        </div>
        <div>
          <div className="label">
            <InputLabel id="tireLabel">Tire</InputLabel>
            <Tooltip title="The tires to use for the scenario">
              <Help color="action"/>
            </Tooltip>
          </div>
          <Select
            className="formControl"
            error={errors.tire}
            labelId="tireLabel"
            name="tire"
            onChange={handleChange}
            value={form.tire}
            variant="outlined"
          >
            { tires.map((tire) => <MenuItem value={tire.id} key={`tire-${tire.id}`}>{tire.name}</MenuItem> )}
          </Select>
          { errors.tire && <FormHelperText className="MuiFormHelperText-contained Mui-error">Required</FormHelperText> }
        </div>
      </div>
      <div>
        <div className="label">
          <InputLabel id="chargeLabel">State of Charge at Route Start</InputLabel>
          <Tooltip title="Percentage of battery remaining for the scenario">
            <Help color="action"/>
          </Tooltip>
        </div>
        <div className="sliderContainer">
          <span className="sliderLabel">{form.battery}%</span>
          <Slider
            max={100}
            min={20}
            name="battery"
            onChange={(e, val) => { setVal('battery', val) }}
            step={1}
            value={form.battery}
            valueLabelDisplay="auto"
          />
        </div>
      </div>
      <div>
        <div className="label">
          <InputLabel id="temperatureLabel">Ambient Temperature</InputLabel>
          <Tooltip title="The ambient temperature to use for the scenario">
            <Help color="action"/>
          </Tooltip>
        </div>
        <div className="sliderContainer">
          <span className="sliderLabel">{form.temperature}&deg; F</span>
          <Slider
            max={120}
            min={-40}
            name="temperature"
            onChange={(e, val) => { setVal('temperature', val) }}
            step={1}
            value={form.temperature}
            valueLabelDisplay="auto"
          />
        </div>
      </div>

      <div>
        <div className="label">
          <FormLabel>Ridership</FormLabel>
          <Tooltip title="The percentage of riders for the scenario">
            <Help color="action"/>
          </Tooltip>
        </div>
        <RadioGroup value={form.ridership} name="ridership" className="row" onChange={handleChange}>
          <FormControlLabel value="0.25" control={<Radio color="primary"/>} label="25%" labelPlacement="bottom"/>
          <FormControlLabel value="0.5" control={<Radio color="primary"/>} label="50%" labelPlacement="bottom"/>
          <FormControlLabel value="0.75" control={<Radio color="primary"/>} label="75%" labelPlacement="bottom"/>
          <FormControlLabel value="1" control={<Radio color="primary"/>} label="100%" labelPlacement="bottom"/>
        </RadioGroup>
      </div>

      <div>
        <div className="label">
          <FormLabel>Driver's Electric Vehicle Experience</FormLabel>
          <Tooltip title="The experience of the driver for the scenario">
            <Help color="action"/>
          </Tooltip>
        </div>
        <RadioGroup value={form.driverExpertise} name="driverExpertise" className="row" onChange={handleChange}>
          <FormControlLabel value="0" control={<Radio color="primary"/>} label="Novice" labelPlacement="bottom"/>
          <FormControlLabel value="1" control={<Radio color="primary"/>} label="Intermediate" labelPlacement="bottom"/>
          <FormControlLabel value="2" control={<Radio color="primary"/>} label="Expert" labelPlacement="bottom"/>
        </RadioGroup>
      </div>

      <div>
        <Button type="submit" variant="contained" color="primary">Submit</Button>
      </div>
    </form>
  );
};

SimulatorFormComponent.propTypes = {
  buses: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
  simulation: PropTypes.object,
  tires: PropTypes.array.isRequired
};

export const SimulatorForm = SimulatorFormComponent;
