import React from 'react';
import ReactDOM from 'react-dom';
import {
  AuthContextProvider,
  ConfigureOAuth
} from 'components/Contexts/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { App } from './Components/App';
import api from './utils/api';
import * as serviceWorker from './serviceWorker';

require('es6-promise').polyfill();
require('isomorphic-fetch');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const initServiceWorker = () => {
  if (process.env.NODE_ENV === 'development') {
    serviceWorker.unregister();
  } else {
    serviceWorker.register();
  }
};

/* Cloudfront does not properly handle 404s, so in the case that the config.json
 does not exists, it will return the index.html
 therefore we need to guard against non-json response */
async function getResponseAsJson(res) {
    try {
        return res.ok ? res.json() : Promise.resolve();
    } catch (e) {
        // res.json failed b/c cloudfront returned index.html b/c of cache miss
        // could also be caused by an invalid json file
        return Promise.resolve();
    }
}

/* attempts a fetch on config.json and returns configuration */
async function fetchConfiguration() {
    return fetch('/config.json', { cache: 'no-store' })
        .then(res => {
            if (res && res.status && String(res.status) === '404') {
                console.error('Error loading configuration');
                throw 'ERROR';
            }
            return getResponseAsJson(res);
        })
        .then(config => {
            if (!config) {
                return null;
            }

            return config;
        })
        .catch(() => Promise.reject(Error('Error loading configuration')));
}

function setApplicationConfiguration(config) {
    api.setConfig(config.baseAPI, config.apiToken, config.simulationAPI);
    return ConfigureOAuth(config.AwsAuthConfig.Auth);
}

function renderApp(config) {
  ReactDOM.render(
      <AuthContextProvider providerName={config.AwsAuthConfig.providerName}>
        <BrowserRouter>
          <App config={config}/>
        </BrowserRouter>
      </AuthContextProvider>,
      document.getElementById('root')
    );
}

/**
 * Loads and sets application configuration prior to loading the application
 *
 * uses local storage cache for fast initial startup
 * and reloads cache from disk at each app load
 *
 * @returns {Promise<void>}
 */
async function bootStrapApplication() {
    initServiceWorker();

    await fetchConfiguration().then(c =>
         setApplicationConfiguration(c)
          .then(() => renderApp(c))
    );
}

bootStrapApplication();
