import React, { Component } from 'react';

export default class BatteryIcon extends Component {
	render() {
		const percentage = `${this.props.chargedPercentage / 100 * 24}`;
		return (
			<svg
				width="24px"
				height="24px"
				viewBox="0 0 30 10"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				xmlSpace="preserve"
				xmlnsserif="http://www.serif.com/"
				style={{
					fillRule: 'evenodd',
					clipRule: 'evenodd',
					strokeLinejoin: 'round',
					strokeMiterlimit: '1.41421'
				}}
			>
				<g id="icon_battery" serifid="icon_battery">
					<path
						id="battery-outline"
						d="M1,1L1,9L27,9L27,1L1,1ZM28,3L29,3C29.552,3 30,3.448 30,4L30,6C30,6.552 29.552,7 29,7L28,7L28,9C28,9.552 27.552,10 27,10L1,10C0.448,10 0,9.552 0,9L0,1C0,0.448 0.448,0 1,0L27,0C27.552,0 28,0.448 28,1L28,3Z"
					/>
					<rect id="battery-percentage" x="2" y="2" width={percentage} height="6" />
				</g>
			</svg>
		);
	}
}
export class HoverWheelBatteryIcon extends Component {
	render() {
		return (
			<svg
				width={this.props.width}
				height={this.props.height}
				viewBox="0 0 41 10"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				style={{
					fillRule: 'evenodd',
					clipRule: 'evenodd',
					strokeLinejoin: 'round',
					strokeMiterlimit: 1.41421
				}}
			>
				<g transform="matrix(1,0,0,1,-0.66612,0)">
					<g transform="matrix(1,0,0,1,0.66612,0)">
						<path
							id="battery"
							d="M1,1L1,9L38,9L38,1L1,1ZM39,3L40,3C40.552,3 41,3.448 41,4L41,6C41,6.552 40.552,7 40,7L39,7L39,9C39,9.552 38.552,10 38,10L1,10C0.448,10 0,9.552 0,9L0,1C0,0.448 0.448,0 1,0L38,0C38.552,0 39,0.448 39,1L39,3Z"
							style={{ fill: this.props.fillColor, fillRule: 'nonzero' }}
						/>
					</g>
					<g transform="matrix(1,0,0,1,0.66612,0)">
						<rect
							id="fill"
							x="2"
							y="2"
							width={this.props.batterywidth}
							height="6"
							style={{ fill: this.props.batteryfill }}
						/>
					</g>
				</g>
			</svg>
		);
	}
}
export class EvMapPin extends Component {
	render() {
		return (
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 46 52"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsxink="http://www.w3.org/1999/xlink"
				xmlSpace="preserve"
				xmlnsserif="http://www.serif.com/"
				className="ev-map-pin"
			>
				<g
					id="Map-Component-Pin-Base-Maintenance"
					serifid="Map/Component/Pin/Base/Maintenance"
					transform="matrix(1,0,0,1,3,2)"
				>
					<g id="Shadow">
						<path
							id="path-1"
							d="M8.066,36.051C3.171,32.405 0,26.573 0,20C0,8.954 8.954,0 20,0C31.046,0 40,8.954 40,20C40,26.792 36.615,32.793 31.439,36.408L20,46.25L8.057,36.051L8.066,36.051Z"
						/>
						<path
							id="path-11"
							serifid="path-1"
							d="M8.066,36.051C3.171,32.405 0,26.573 0,20C0,8.954 8.954,0 20,0C31.046,0 40,8.954 40,20C40,26.792 36.615,32.793 31.439,36.408L20,46.25L8.057,36.051L8.066,36.051Z"
							style={{ fill: 'white' }}
						/>
					</g>
					<path
						className={`${this.props.stateColor}-pin-icon`}
						id="Color"
						d="M8.066,36.051C3.171,32.405 0,26.573 0,20C0,8.954 8.954,0 20,0C31.046,0 40,8.954 40,20C40,26.792 36.615,32.793 31.439,36.408L20,46.25L8.057,36.051L8.066,36.051Z"
					/>
					<path
						id="Border"
						d="M8.066,36.051C3.171,32.405 0,26.573 0,20C0,8.954 8.954,0 20,0C31.046,0 40,8.954 40,20C40,26.792 36.615,32.793 31.439,36.408L20,46.25L8.057,36.051L8.066,36.051ZM9.259,34.446L9.251,34.446L20,43.625L30.295,34.767C34.953,31.514 38,26.113 38,20C38,10.059 29.941,2 20,2C10.059,2 2,10.059 2,20C2,25.916 4.854,31.165 9.259,34.446Z"
						style={{ fill: 'white' }}
					/>
					<g id="Icon" transform="matrix(1,0,0,1,10,10)">
						<path
							d="M10,1C5.029,1 1,5.03 1,10C1,14.97 5.029,19 10,19C14.971,19 19,14.971 19,10C19,5.029 14.971,1 10,1ZM13.301,9.469C11.962,11.788 10.622,14.107 9.282,16.426C9.278,16.435 9.272,16.443 9.266,16.452C9.175,16.623 9.068,16.786 8.841,16.703C8.603,16.617 8.659,16.408 8.69,16.231C8.794,15.647 8.907,15.065 9.016,14.484C9.21,13.46 9.404,12.437 9.596,11.413C9.612,11.326 9.617,11.236 9.632,11.111C9.506,11.106 9.399,11.095 9.292,11.096C8.536,11.099 7.779,11.104 7.022,11.107C6.577,11.108 6.471,10.922 6.697,10.531C8.036,8.212 9.376,5.893 10.716,3.575C10.72,3.566 10.726,3.557 10.732,3.548C10.822,3.377 10.93,3.215 11.157,3.297C11.395,3.383 11.338,3.592 11.307,3.77C11.204,4.353 11.091,4.935 10.982,5.516C10.788,6.54 10.594,7.563 10.402,8.587C10.385,8.674 10.381,8.764 10.366,8.889C10.492,8.895 10.599,8.905 10.705,8.904C11.462,8.902 12.219,8.896 12.976,8.894C13.422,8.894 13.527,9.078 13.301,9.469Z"
							style={{ fill: 'white' }}
						/>
					</g>
				</g>
			</svg>
		);
	}
}

export class CloseWheelIcon extends Component {
	render() {
		return (
			<svg
				width="30px"
				height="30px"
				viewBox="0 0 24 24"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				style={{
					fillRule: 'evenodd',
					clipRule: 'evenodd',
					strokeLinejoin: 'round',
					strokeMiterlimit: 1.41421
				}}
			>
				<g id="icon--close">
					<circle id="Oval" cx="12" cy="12" r="12" />
					<path
						id="Fill-1"
						d="M16.836,7.165C16.616,6.945 16.26,6.945 16.041,7.165L12,11.206L7.959,7.165C7.74,6.945 7.383,6.945 7.165,7.165C6.945,7.385 6.945,7.741 7.165,7.96L11.206,12L7.165,16.04C6.946,16.259 6.946,16.615 7.165,16.835C7.274,16.945 7.418,17 7.562,17C7.706,17 7.85,16.945 7.959,16.836L12,12.795L16.04,16.836C16.15,16.945 16.294,17 16.437,17C16.581,17 16.725,16.945 16.835,16.835C17.054,16.615 17.054,16.259 16.834,16.04L12.794,12L16.834,7.96C17.055,7.741 17.055,7.385 16.836,7.165L16.836,7.165Z"
						style={{ fill: 'white' }}
					/>
				</g>
			</svg>
		);
	}
}

export class ElectricityIcon extends Component {
	render() {
		return (
			<svg
				width="24"
				height="24"
				fill={this.props.fillColor}
				viewBox="0 0 24 24"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				xmlSpace="preserve"
				xmlnsserif="http://www.serif.com/"
				style={{
					fillRule: 'evenodd',
					clipRule: 'evenodd',
					strokeLinejoin: 'round',
					strokeMiterlimit: '1.41421'
				}}
			>
				<path
					id="Icon"
					d="M12,1C5.924,1 1,5.926 1,12C1,18.074 5.924,23 12,23C18.076,23 23,18.076 23,12C23,5.924 18.076,1 12,1ZM16.034,11.351C14.398,14.185 12.76,17.019 11.123,19.853C11.117,19.865 11.11,19.874 11.103,19.886C10.992,20.095 10.86,20.294 10.583,20.193C10.293,20.088 10.361,19.832 10.399,19.615C10.525,18.902 10.664,18.191 10.797,17.48C11.034,16.229 11.271,14.979 11.507,13.727C11.526,13.62 11.532,13.511 11.55,13.358C11.396,13.351 11.266,13.339 11.135,13.34C10.21,13.343 9.285,13.35 8.36,13.353C7.816,13.354 7.687,13.127 7.963,12.649C9.599,9.815 11.238,6.981 12.875,4.147C12.88,4.136 12.887,4.126 12.894,4.115C13.005,3.906 13.137,3.707 13.414,3.808C13.705,3.913 13.636,4.168 13.598,4.385C13.472,5.099 13.333,5.809 13.2,6.52C12.963,7.771 12.726,9.022 12.491,10.273C12.471,10.38 12.466,10.489 12.447,10.642C12.601,10.649 12.732,10.662 12.862,10.66C13.787,10.657 14.712,10.65 15.637,10.648C16.183,10.648 16.31,10.873 16.034,11.351Z"
				/>
			</svg>
		);
	}
}
export class ResetIcon extends Component {
	render() {
		return (
			<svg
				width="22px"
				height="22px"
				viewBox="0 0 22 22"
				fill="red"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
			>
				<g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g
						id="Icons/Zoom--Reset--Glass--Solid"
						transform="translate(-1.000000, -1.000000)"
						fill={this.props.fillcolor}
					>
						<path
							d="M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,13.8386864 23,17.1720197 23,22 C23,22.5522809 22.5522879,22.999993 22.000007,23 C17.2851506,23 13.9518149,23 12,23 Z M7.76625,7.7625 L6.00375,6 L6.00375,11.25 L11.25375,11.25 L8.83875,8.835 C9.64875,8.0175 10.75875,7.5 12.00375,7.5 C14.48625,7.5 16.50375,9.5175 16.50375,12 C16.50375,14.4825 14.48625,16.5 12.00375,16.5 C10.04625,16.5 8.38125,15.2475 7.76625,13.5 L6.20625,13.5 C6.87375,16.0875 9.20625,18 12.00375,18 C15.31875,18 17.99625,15.315 17.99625,12 C17.99625,8.685 15.31875,6 12.00375,6 C10.34625,6 8.85375,6.675 7.76625,7.7625 Z"
							id="Icon"
						/>
					</g>
				</g>
			</svg>
		);
	}
}
