import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Offlinebolt from '@material-ui/icons/OfflineBolt';
import Radio from '@material-ui/core/Radio';
import { format } from 'date-fns';
import { formatDateTimeToLocal } from 'utils/dateUtils';
import { Link } from 'react-router-dom';
import './FaultsPagedTable.css';
import { CircularProgress } from '@material-ui/core';
import HappyBus from '../../../assets/Icons/happy-bus.svg';

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className='cell'>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}

export function FaultsPagedTable(props) {
  const { faults } = props;
  const active = [];
  const history = [];
  if (faults) {
    faults.sort((a, b) => a.priorityCode - b.priorityCode);
    faults.forEach(fault => {
      if (fault.faultStatus === 'true') {
        active.push(fault);
      } else {
        history.push(fault);
      }
    });
    history.sort(
      (a, b) => new Date(b.dateOfOccurence) - new Date(a.dateOfOccurence)
    );
  }

  const [page, setPage] = React.useState(0);
  const [page2, setPage2] = React.useState(0);
  const rowsPerPage = 3;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  return faults ? (
    active.length > 0 || history.length > 0 ? (
      <>
        <div className='table-container'>
          <div className='issue-title'>Active Faults</div>
          <Table className='fault-table'>
            <TableHead>
              <TableRow>
                <TableCell className='date-cell'>
                  Fault Occurrence Date
                </TableCell>
                {!props.isSingleBus && (
                  <TableCell className='bus-cell'>Bus</TableCell>
                )}
                <TableCell className='desc-cell'>Fault</TableCell>
                <TableCell className="occurence-cell">Occurrence Count</TableCell>
                <TableCell>Severity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {active
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((fault, index) => (
                  <TableRow key={index}>
                    <TableCell className='date-cell'>
                      {/* {format(new Date(fault.dateOfOccurence), 'MMMM d, yyyy')} */}
                      {format(new Date(formatDateTimeToLocal(fault.dateOfOccurence)),'MMMM d, yyyy')}
                    </TableCell>
                    {!props.isSingleBus && (
                      <TableCell className='bus-cell'>
                        <div className='cell'>
                          <Offlinebolt className='bolt-icon' />
                          {fault.busStatus ? (
                            <Link to={`/buses/${fault.esn}/today`}>
                              {fault.busStatus.customerEquipId}
                            </Link>
                          ) : (
                            'N/A'
                          )}
                        </div>
                      </TableCell>
                    )}
                    <TableCell className='desc-cell'>
                      <Link
                        to={`/faults-and-alerts/issues/${fault.faultID}?esn=${fault.esn}`}
                      >
                        {fault.faultDescription}
                      </Link>
                    </TableCell>
                    <TableCell className="occurenceCount-cell">{fault.faultOccurrenceCount}</TableCell>
                    <TableCell className='severity-cell'>
                      <div className='cell'>
                        <Radio checked style={{ color: `${fault.color}` }} />
                        {fault.severity}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[rowsPerPage]}
                  colSpan={props.isSingleBus ? 2 : 3}
                  count={active.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        <div className='table-container'>
          <div className='issue-title'>Inactive Faults</div>
          <Table className='fault-table'>
            <TableHead>
              <TableRow>
                <TableCell className='date-cell'>
                  Fault Occurrence Date
                </TableCell>
                {!props.isSingleBus && (
                  <TableCell className='bus-cell'>Bus</TableCell>
                )}
                <TableCell className='desc-cell'>Fault</TableCell>
                <TableCell className='occurence-cell'>Occurrence Count</TableCell>
                <TableCell>Severity</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {history
                .slice(page2 * rowsPerPage, page2 * rowsPerPage + rowsPerPage)
                .map(fault => (
                  <TableRow key={`${fault.esn}-${fault.faultID}`}>
                    <TableCell className='date-cell'>
                      {/* {format(new Date(fault.dateOfOccurence), 'MMMM d, yyyy')} */}
                      {format(new Date(formatDateTimeToLocal(fault.dateOfOccurence)),'MMMM d, yyyy')}
                    </TableCell>
                    {!props.isSingleBus && (
                      <TableCell className='bus-cell'>
                        <div className='cell'>
                          <Offlinebolt className='bolt-icon' />
                          {fault.busStatus ? (
                            <Link to={`/buses/${fault.esn}/today`}>
                              {fault.busStatus.customerEquipId}
                            </Link>
                          ) : (
                            'N/A'
                          )}
                        </div>
                      </TableCell>
                    )}
                    <TableCell className='desc-cell'>
                      <Link
                        to={`/faults-and-alerts/issues/${fault.faultID}?esn=${fault.esn}`}
                      >
                        {fault.faultDescription}
                      </Link>
                    </TableCell>
                    <TableCell className='occurenceCount-cell'>{fault.faultOccurrenceCount}</TableCell>
                    <TableCell className='severity-cell'>
                      <div className='cell'>
                        <Radio checked style={{ color: `${fault.color}` }} />
                        {fault.severity}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[rowsPerPage]}
                  colSpan={props.isSingleBus ? 2 : 3}
                  count={history.length}
                  rowsPerPage={rowsPerPage}
                  page={page2}
                  onChangePage={handleChangePage2}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </>
    ) : (
      <div className='no-active'>
        <img src={HappyBus} alt='bus icon' height='130' />
        You have no issues for your fleet
      </div>
    )
  ) : (
    <div className='page-loader'>
      <CircularProgress />
    </div>
  );
}
