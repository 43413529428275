import React, { Component } from 'react';
import { logEvent } from 'utils/analyticsTracking';
import HoverWheel from './HoverWheel-View';
import './hoverWheel.scss';

class HoverWheelContainer extends Component {
	constructor(props) {
		super(props);
		this.icons = {
			red: 'red',
			yellow: 'yellow',
			green: 'green',
			orange: 'orange',
			purple: 'purple'
		};
		this.pinWheelRef = React.createRef();
	}

	onMarkerClick = (busInfo) => {
		if (this.props.isActive) {
		  if (busInfo) {
        logEvent({
          action: 'navigate',
          category: 'Hover Wheel',
          label: 'pin'
        });
        this.props.history.push(`/buses/${busInfo.esn.S}/today`);
      }
		} else {
			logEvent({
        action: 'open',
        category: 'Hover Wheel',
        label: 'pin'
      });
      this.props.onMarkerClick();
		}
	};

	onClickClose = (e) => {
		e.preventDefault();
    logEvent({
      action: 'close',
      category: 'Hover Wheel',
      label: 'pin'
    });
    this.pinWheelRef.current.parentElement.parentElement.parentElement.parentElement.style.backgroundColor =
			'';
		this.props.closeIconClick();
	};

	getChargeStateIconColor = (chargeState) => {
		const value = parseFloat(chargeState && chargeState.S);
		let path = '';
		if (value >= 0 && value < 20) {
			path = this.icons.red;
		} else if (value >= 20 && value < 50) {
			path = this.icons.yellow;
		} else if (value >= 50) {
			path = this.icons.green;
		} else {
			path = this.icons.red;
		}
		return path;
	};

	getFaultSeverityColor = (priority) => {
		switch (priority && priority.toLowerCase()) {
			case 'stop now':
				return this.icons.red;
			case 'service now':
				return this.icons.orange; // orange
			case 'service soon':
				return this.icons.yellow;
			case 'information':
				return this.icons.purple; // purple
			default:
				return this.icons.green;
		}
	};

	getActiveFaultSeverityColor = (severity) => {
		switch (severity && severity.severity_id) {
			case '1':
				return this.icons.red;
			case '2':
				return this.icons.orange; // orange
			case '3':
				return this.icons.yellow;
			case '4':
				return this.icons.purple; // purple
			default:
				return this.icons.green;
		}
	};

	getMostFaultSeverityColor = () => {
		const { busContextData, busInfo } = this.props;
		if (!busContextData.activeAlertData) return this.icons.green;

		const busAlerts = busContextData.activeAlertData.filter(
			(alert) => alert.busID === busInfo.customerEquipId.S
		);

		if (!busAlerts.length) return this.icons.green;

		const mostSeverityAlert = busAlerts.sort((a, b) => a.severityId - b.severityId)[0];

		return this.getFaultSeverityColor(mostSeverityAlert.severity);
	};

	timeExceeds = (date) => {
		const splitDate = date.split(' ');
		if (
			splitDate[1] === 'year' ||
			splitDate[1] === 'month' ||
			splitDate[1] === 'months' ||
			splitDate[1] === 'week' ||
			splitDate[1] === 'days' ||
			splitDate[1] === 'day'
		) {
			return true;
		}
		if (splitDate[1] === 'hours' && splitDate[0] >= 24) {
			return true;
		}
		return false;
	};

	formatDate = () => {
		const d = new Date();
		const year = d.getFullYear();
		let month = `${d.getMonth() + 1}`;
		let day = `${d.getDate()}`;

		if (month.length < 2) month = `0${month}`;
		if (day.length < 2) day = `0${day}`;

		return [ year, month, day ].join('-');
	};

	timeSince = (date) => {
		const seconds = Math.floor((new Date() - date) / 1000);

		let interval = Math.floor(seconds / 31536000);

		if (interval > 1) {
			return `${interval} years`;
		}
		interval = Math.floor(seconds / 2592000);
		if (interval > 1) {
			return `${interval} months`;
		}
		interval = Math.floor(seconds / 86400);
		if (interval > 1) {
			return `${interval} days`;
		}
		interval = Math.floor(seconds / 3600);
		if (interval > 1) {
			return `${interval} hours`;
		}
		interval = Math.floor(seconds / 60);
		if (interval > 1) {
			return `${interval} minutes`;
		}
		return `${Math.floor(seconds)} seconds`;
	};

	render() {
		const {
			busInfo,
			customName,
			width,
			height,
			busDetails,
			busContextData,
			showAlertDataSeverity,
			history
		} = this.props;
		const mostPriorityFault = busContextData.customerNameData
			? busContextData.customerNameData.busIssues
			: '';
		let stateColor = this.getChargeStateIconColor(busInfo.chargeState);
		if (busDetails) {
			stateColor = this.getFaultSeverityColor(this.props.busDetails.severity);
		}
		if ((!busDetails || busInfo.locationName === 'Current Location') && mostPriorityFault) {
			const severity = mostPriorityFault.find(
				(priority) => priority.engineSerialNum === busInfo.esn.S
			);
			stateColor = this.getActiveFaultSeverityColor(severity);
		}

		if (showAlertDataSeverity) {
			stateColor = this.getMostFaultSeverityColor();
		}
		const chargeState = !busInfo.chargeState
			? 0
			: !busInfo.chargeState.S || busInfo.chargeState.S === 'N/A' ? 0 : busInfo.chargeState.S;
		const battery = parseFloat(chargeState * 35) / 100;
		const isConnected = !!(
			(busInfo.chargeConnection && busInfo.chargeConnection.S === '4') ||
			(busInfo.chargeConnection && busInfo.chargeConnection.S === '9')
		);
		let averageKMDisplay = 'N/A';
		if (busContextData && busContextData.averageKM && busContextData.averageKM.length > 0) {
			for (let i = 0; i < busContextData.averageKM.length; i += 1) {
				if (busInfo.esn.S === busContextData.averageKM[i].engineSerialNumber) {
					averageKMDisplay = Math.round(busContextData.averageKM[i].kWhpermi * 100) / 100;
				}
			}
		}

		return (
			<HoverWheel
				isActive={this.props.isActive}
				pinWheelRef={this.pinWheelRef}
				busInfo={busInfo}
				battery={battery}
				averageKMDisplay={averageKMDisplay}
				stateColor={stateColor}
				onMarkerClick={this.onMarkerClick}
				customName={customName}
				showBatteryState={this.props.showBatteryState}
				height={height}
				width={width}
				hideClose={this.props.hideClose}
				onClickClose={this.onClickClose}
				isConnected={isConnected}
				timeExceeds={this.timeExceeds}
				history={history}
			/>
		);
	}
}

export default HoverWheelContainer;
